@import url(https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&family=Poppins:ital,wght@0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,500;1,600;1,700;1,800;1,900&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.header-logo {
  width: 5.78em;
  height: 5.78em;
  border-radius: 50%;
}
.footer {
  flex: 0 0 2.7em;
  padding: 1.2em 0 1.1em;
}

.btn-gradient {
  background: transparent linear-gradient(90deg, #bc00ff 0%, #ff1aa1 100%) 0% 0%
    no-repeat padding-box;
  box-shadow: 0 0.7em 2.14em #94006733;
  color: #fff;
}

.btn-gradient:hover {
  background: transparent linear-gradient(110deg, #ff1aa1 35%, #bc00ff 100%) 0%
    0% no-repeat padding-box;
}

.btn-default {
  border-radius: 1.78em;
  font: normal normal normal 1em/1.5em Poppins;
  letter-spacing: 0;
  padding: 0 1.85em;
  height: 3.57em;
  font-weight: 500;
}
.header-options .btn {
  margin-left: 1.42em;
}
.btn-theme {
  width: 3.57em;
  aspect-ratio: 1/1;
  border-radius: 50%;
}
.header-links ul li {
  display: inline-flex;
  align-items: center;
  position: relative;
}
.header-links ul li:not(:last-child) {
  margin-right: 2em;
}
.header-links ul li a {
  font-size: 1.28em;
  line-height: 1.92em;
  letter-spacing: 0em;
  height: 3.07em;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.header-links ul li a.readBtn {
  padding: 7px 16px;
  height: auto;
  position: relative;
  -webkit-transform: translate(0%, 0%);
  transform: translate(0%, 0%);
  overflow: hidden;
  position: relative;
}

.header-links ul li a.readBtn span {
  position: absolute;
}

.header-links ul li a.readBtn span:nth-child(1) {
  top: 0px;
  left: 0px;
  width: 100%;
  height: 2px;
  /* background: -webkit-gradient(linear, right top, left top, from(rgba(43, 8, 8, 0)), to(#d0f224));
  background: linear-gradient(to left, rgba(43, 8, 8, 0), #d0f224); */
  background: transparent linear-gradient(180deg, #ff8dd8 0%, #f817ab 100%) 0%
    0% no-repeat padding-box;
  box-shadow: 0px 0px 4px rgba(248, 23, 171, 0.5);
  -webkit-animation: 2s contactAnimateTop linear infinite;
  animation: 2s contactAnimateTop linear infinite;
}

.header-links ul li a.readBtn span:nth-child(2) {
  top: 0px;
  right: 0px;
  height: 100%;
  width: 2px;
  /* background: -webkit-gradient(linear, left bottom, left top, from(rgba(43, 8, 8, 0)), to(#d0f224));
    background: linear-gradient(to top, rgba(43, 8, 8, 0), #d0f224); */
  background: transparent linear-gradient(180deg, #ffffff 0%, #f8d704 100%) 0%
    0% no-repeat padding-box;
  -webkit-animation: 2s contactAnimateRight linear -1s infinite;
  animation: 2s contactAnimateRight linear -1s infinite;
}

.header-links ul li a.readBtn span:nth-child(3) {
  bottom: 0px;
  left: 0px;
  width: 100%;
  height: 2px;
  /* background: -webkit-gradient(linear, left top, right top, from(rgba(43, 8, 8, 0)), to(#d0f224));
    background: linear-gradient(to right, rgba(43, 8, 8, 0), #d0f224); */
  background: transparent linear-gradient(180deg, #ffffff 0%, #f8d704 100%) 0%
    0% no-repeat padding-box;
  -webkit-animation: 2s contactAnimateBottom linear infinite;
  animation: 2s contactAnimateBottom linear infinite;
}

.header-links ul li a.readBtn span:nth-child(4) {
  top: 0px;
  left: 0px;
  height: 100%;
  width: 2px;
  /* background: -webkit-gradient(linear, left top, left bottom, from(rgba(43, 8, 8, 0)), to(#d0f224));
    background: linear-gradient(to bottom, rgba(43, 8, 8, 0), #d0f224); */
  background: transparent linear-gradient(180deg, #ff8dd8 0%, #f817ab 100%) 0%
    0% no-repeat padding-box;
  box-shadow: 0px 0px 4px rgba(248, 23, 171, 0.5);
  -webkit-animation: 2s contactAnimateLeft linear -1s infinite;
  animation: 2s contactAnimateLeft linear -1s infinite;
}

@-webkit-keyframes contactAnimateTop {
  0% {
    -webkit-transform: translateX(100%);
    transform: translateX(100%);
  }
  100% {
    -webkit-transform: translateX(-100%);
    transform: translateX(-100%);
  }
}

@keyframes contactAnimateTop {
  0% {
    -webkit-transform: translateX(100%);
    transform: translateX(100%);
  }
  100% {
    -webkit-transform: translateX(-100%);
    transform: translateX(-100%);
  }
}
@-webkit-keyframes contactAnimateRight {
  0% {
    -webkit-transform: translateY(100%);
    transform: translateY(100%);
  }
  100% {
    -webkit-transform: translateY(-100%);
    transform: translateY(-100%);
  }
}
@keyframes contactAnimateRight {
  0% {
    -webkit-transform: translateY(100%);
    transform: translateY(100%);
  }
  100% {
    -webkit-transform: translateY(-100%);
    transform: translateY(-100%);
  }
}
@-webkit-keyframes contactAnimateBottom {
  0% {
    -webkit-transform: translateX(-100%);
    transform: translateX(-100%);
  }
  100% {
    -webkit-transform: translateX(100%);
    transform: translateX(100%);
  }
}
@keyframes contactAnimateBottom {
  0% {
    -webkit-transform: translateX(-100%);
    transform: translateX(-100%);
  }
  100% {
    -webkit-transform: translateX(100%);
    transform: translateX(100%);
  }
}
@-webkit-keyframes contactAnimateLeft {
  0% {
    -webkit-transform: translateY(-100%);
    transform: translateY(-100%);
  }
  100% {
    -webkit-transform: translateY(100%);
    transform: translateY(100%);
  }
}
@keyframes contactAnimateLeft {
  0% {
    -webkit-transform: translateY(-100%);
    transform: translateY(-100%);
  }
  100% {
    -webkit-transform: translateY(100%);
    transform: translateY(100%);
  }
}

.header-links ul li a.dashUnder.readBtn:hover::after {
  display: none;
}

.dashUnder:after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  height: 0.28em;
  background: transparent
    linear-gradient(
      90deg,
      #ff1aa1 0%,
      var(--unnamed-color-bc00ff) 47%,
      #1e0b3f26 100%
    )
    0% 0% no-repeat padding-box;
  background: transparent
    linear-gradient(90deg, #ff1aa1 0%, #bc00ff 47%, #1e0b3f26 100%) 0% 0%
    no-repeat padding-box;
  border-radius: 0.85em;
  width: 6.35em;
  -webkit-transform: translateX(130%);
          transform: translateX(130%);
  opacity: 0;
  transition: all cubic-bezier(0.175, 0.885, 0.32, 1.275) 0.2s;
}
.header-links ul li .dashUnder:hover:after {
  -webkit-transform: translateX(0);
          transform: translateX(0);
  opacity: 1;
}
.header-links ul .docLink svg {
  transition: all cubic-bezier(0.175, 0.885, 0.32, 1.275) 0.2s;
}
.header-links ul .docLink:hover svg {
  opacity: 0;
}
.header-links ul {
  display: flex;
}

.header {
  padding: 2.25em 0 0 0;
  margin-bottom: -12px;
}
.header-links {
  margin-left: 9.85em;
}
.contentMain {
  overflow: hidden;
  padding-top: 0;
  padding-bottom: 3.22em;
  justify-content: center;
}
.docLink svg {
  position: absolute;
  left: 50%;
  pointer-events: none;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
}

/* footer */

.footerLink li:not(:last-child) {
  margin-right: 1.42em;
}
.copyText {
  margin: auto;
}
.copyText p {
  text-align: left;
  font-size: normal normal normal 1em;
  line-height: 1.78em;
  letter-spacing: 0.042em;
}
.freeFaucet a span {
  font-size: 1.14em;
  line-height: 1.78em;
  color: #ff1aa1;
  margin-right: 0.71em;
  vertical-align: middle;
}
/* footer */

/* Main */
.headerMain h3 {
  font-weight: normal;
}
.headerMain {
  position: relative;
}
.headerMain::after {
  opacity: 1;
  left: 50%;
  height: 0.57em;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  bottom: -0.85em;
}
.mucards {
  margin-top: 5em;
  grid-column-gap: 4.05em;
  -webkit-column-gap: 4.05em;
          column-gap: 4.05em;
  padding: 0px 49px;
  position: relative;
}
.mucards::before {
  content: "";
  position: absolute;
  top: -62px;
  left: 50px;
  width: 1362px;
  height: 447px;
  background-image: url(/static/media/circles.9311ace2.png);
  background-repeat: no-repeat;
}
.card {
  max-width: 25%;
  flex: 1 1 25%;
  height: 26.25em;
  border: 2px solid var(--unnamed-color-e2e0e099);
  background: transparent linear-gradient(140deg, #e2e0e066 0%, #e2e0e00d 100%)
    0% 0% no-repeat padding-box;
  border: 2px solid #e2e0e099;
  border-radius: 10px;
  opacity: 1;
  backdrop-filter: blur(50px);
  -webkit-backdrop-filter: blur(50px);
  background-attachment: fixed;
  padding: 1.42em 1.02em;
  padding-top: 55px;
  max-width: 19.5em;

  position: relative;
  text-align: center;
}
.muCoin {
  width: 10.21em;
  height: 10.21em;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 1.42em;
  margin-inline: auto;
  background-color: #d2d2d2;
  border-radius: 50%;
}
.muCoin img {
  max-width: 6.92em;
}
.muGold .muCoin {
  background: #f4d974;
}
.muOre .muCoin {
  background: #ffbfa2;
}
.muVault .muCoin {
  background: #9099b5;
}

.muAmtComp {
  height: 68px;
  display: flex;
  align-items: center;
  position: relative;
  padding-left: 0.85em;
  padding-right: 0.85em;
  padding-top: 1em;
  padding-bottom: 0.85em;
  border-radius: 0 5px 5px 0;
  position: relative;
  overflow: hidden;
}

.muAmtComp:after {
  position: absolute;
  content: "";
  left: 0;
  width: 2px;
  height: 100%;
  background: #d2d2d2;
}
.muGold .muAmtComp:after {
  background-color: #f4d974;
}
.muOre .muAmtComp:after {
  background-color: #ffbfa2;
}
.muVault .muAmtComp:after {
  background-color: #9099b5;
}
.muAmtComp h5 {
  font-size: 1em;
}
.muAmtComp span {
  color: #d6d6d6;
  letter-spacing: 0.48px;
  font-size: 0.85em;
  line-height: 1.28em;
}
.muAmtComp span.valueSpan {
  font-size: 0.85em;
}
.muAmtComp span.valuationSpan {
  color: #fff;
}
.card footer .btn {
  font-size: 0.85em;
  width: 100%;
  font-weight: 500;
}
.card footer {
  margin-top: 1.42em;
}
.btn-double {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  text-align: center;
}
.topDeck em {
  max-width: 165px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline-block;
  vertical-align: middle;
}

button.btn.btn-gradient.btn-default.disabled {
  cursor: not-allowed;
}
.topDeck figure {
  display: inline-block;
  margin-right: 3px;
  line-height: 1;
}

.header-options .btn-double {
  text-align: center;
  font: normal normal normal 12px/18px Poppins;
  letter-spacing: 0px;
  height: 50px;
  border-radius: 25px;
}
.header-options .btn-double .bottomDeck,
.header-options .btn-double .topDeck {
  padding: 0 5px;
}
.header-options .btn-double .bottomDeck {
  padding-top: 3px;
  border-top: 1px solid;
}
.header-options .btn-double .topDeck {
  padding-bottom: 3px;
}

.comingSoon {
  margin-top: 48px;
}

.headerBtnsCollections {
  position: relative;
}

.noBalance {
  padding: 1.74em 1.5em;
}

.displayingValue {
  position: relative;
}
.displayingValueWraper:hover .currentValueDropdown {
  display: block;
}

.noBalance.currentValueDropdown p:last-child {
  margin-bottom: 0;
}

.haveBalance {
  padding: 0.2em 1.1em;
}

.individualListingLi {
  display: flex;
  align-items: center;
  padding: 0.9em 0.7em;
}

.individualListingLi.disconBtn:hover,
.individualListingLi.totals:hover {
  background: #000b2c;
}

.individualListingLi figure {
  width: 29px;
  height: 29px;
  border-radius: 50%;
  overflow: hidden;
  margin-right: 11px;
}

.dropdownIcons {
  width: 100%;
  height: 100%;
}
.dropsownInfoWraper {
  width: calc(100% - 40px);
}

.currentValueDropdown {
  display: none;
}

.totalValue {
  font-size: 1em;
  font-weight: 500;
}

.totalValuation {
  font-size: 1em;
  margin-left: auto;
  font-weight: 500;
}

.individualListingLi.totals {
  border-bottom: none;
}

.displayingValueWraper {
  position: relative;
}

.disconnectedBtns {
  border-radius: 20px;
  padding: 1em;
  width: 12em;
  text-align: center;
  display: block;
  margin-left: auto;
  margin-right: auto;
  border: none;
}
.disconBtn {
  padding-top: 0;
}

.modalHedading,
.modalSubHedading {
  width: 100%;
  display: flex;
  align-items: center;
}
.modalBody {
  margin: 40px 0 10px 0;
}

.currencyIcon {
  display: flex;
  align-items: center;
  margin-right: 20px;
}

.currencyIcon span {
  margin-left: 10px;
  font-size: 1em;
  color: #9099b5;
}

.inputFieldSection {
  margin-right: 20px;
  width: auto;
  min-width: 42%;
}

.inputFieldSection input {
  border: none;
  box-shadow: none;
  font-size: 1em;
  width: 100%;
}

/* Chrome, Safari, Edge, Opera */
.inputFieldSection input::-webkit-outer-spin-button,
.inputFieldSection input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
.inputFieldSection input[type="number"] {
  -moz-appearance: textfield;
}

.inputFieldSection input::-webkit-input-placeholder {
  /* Edge */
  color: #505a7b;
  font-size: 0.85em;
}

.inputFieldSection input::placeholder {
  color: #505a7b;
  font-size: 0.85em;
}

.maxButton {
  border-radius: 25px;
  opacity: 1;
  font-size: 1em;
  color: #fff;
  cursor: pointer;
  padding: 0.64em 2.35em;
  box-shadow: none;
  border: none;
  outline: none;
}
.btnMax {
  margin-left: auto;
}

.alterBtnRow {
  width: 100%;
  text-align: center;
  display: flex;
  margin: 20px 0;
  align-items: center;
}
.alterBtnRow img {
  margin-left: auto;
  margin-right: auto;
  cursor: pointer;
  width: 46px;
  height: 46px;
}

.currencyIcon img {
  width: 28px;
  height: 28px;
}

.modal-content.succesFailResult {
  background-image: url(/static/media/success.e065ad05.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  height: 653px;
  padding: 2em;
}

#succesFailClose {
  margin-bottom: 0;
}

.modalSuccess {
  text-align: center;
}

.modalSuccess h1 {
  color: #fff;
  font-size: 2.85em;
  margin-bottom: 5px;
  line-height: 1.28em;
}

.modalSuccess p {
  font-size: 1.71em;
  letter-spacing: 0.05em;
  color: #c0c0c0;
  line-height: 1.58em;
  margin-bottom: 20px;
}

.hilighted {
  color: #ff1aa1;
  font-weight: 500;
}

.close-button {
  float: right;
  text-align: center;
  cursor: pointer;
  margin-left: auto;
  margin-bottom: 20px;
}

.close-button img {
  width: 1em;
  height: 1em;
}

.modalSuccess .swapBtn {
  width: 320px;
}
.alertIcons {
  margin-bottom: 2.85em;
}

.failureModal h1 {
  font-size: 2.7em;
  margin-bottom: 1.425em;
}

.failureModal p {
  color: #d2d2d2;
  font-weight: 400;
  font-size: 1.58em;
  margin-bottom: 2.2em;
}

.failureModal .swapBtn.btn-gradient {
  margin-bottom: 2.35em;
}
.freeFaucet svg {
  width: 40px;
  height: 30px;
}
.modalBody .alterBtnRow .swapIcons {
  margin: 0 auto;
  cursor: pointer;
}

button.btn.btn-gradient.btn-default.comingSoonBtn.disabled {
  opacity: 0.72;
}

@media only screen and (max-width: 1650px) {
  h1 {
    font-size: 3.21em;
    line-height: 50px;
  }
  .contentMain {
    padding-top: 1.22em;
    padding-bottom: 3.22em;
  }
}

@media only screen and (max-width: 1550px) {
  h1 {
    font-size: 3.21em;
    line-height: 50px;
  }
  .contentMain {
    padding-top: 0.22em;
    padding-bottom: 1.22em;
  }
}
@media only screen and (max-width: 1350px) {
  .card footer .btn {
    font-size: 0.7em;
  }
}
@media only screen and (max-width: 1255px) {
  .header-links ul li a {
    font-size: 0.85em !important;
  }
  .header-links {
    margin-left: 2.5em !important;
  }
  .btn.btn-dark.btn-default {
    font-size: 0.75em !important;
  }
  .header-links ul li:not(:last-child) {
    margin-right: 1.75em !important;
  }
}

@media only screen and (max-width: 1150px) {
  .card footer .btn {
    font-size: 0.65em;
  }
}

span.conditioWraper {
  display: contents;
}

span.valueSpan.coming {
  font-size: 14px;
  font-weight: 700;
}

.approvingSuccess {
  text-align: center;
  margin: 15px 0;
  width: 100%;
}

.disabledClick {
  pointer-events: none;
}

.buttonWrapers {
  width: 100%;
  display: flex;
}

.muGoldValues {
  width: 100%;
  display: flex;
  align-items: center;
  position: absolute;
  top: 0;
  left: auto;
  right: 0;
  text-align: center;
  padding: 2px 12px;
}

.muGoldValues span {
  font-size: 9.5px;
}

.textMu.lefts {
  margin-right: auto;
}
.textMu.rights {
  margin-left: auto;
}

.addToWalet {
  position: absolute;
  bottom: -40px;
  left: 0;
  right: 0;
  font-size: 16px;
  letter-spacing: 0px;
  color: #ff1aa1;
  opacity: 1;
  cursor: pointer;
  text-decoration: underline;
}

.miningInfo {
  width: 100%;
  position: absolute;
  text-align: center;
  left: 0;
  top: 0;
  padding: 7px 5px;
  border-radius: 8px 8px 0px 0px;
}

.logoutTexts {
  margin: 0 !important;
  padding-top: 25px;
}

.modal-content {
  padding: 20px 39px 40px 39px;
}

#notAccepted {
  pointer-events: none;
  opacity: 0.4;
}

iframe {
  z-index: -1 !important;
}

.tooltip_up {
  position: relative;
}
.tooltip_up p {
  display: flex;
  align-items: center;
  grid-gap: 5px;
  gap: 5px;
}
.tooltip_up .tooltip {
  transition: 0.3s;
  content: "";
  position: absolute;
  top: 0;
  left: -122px;
  position: absolute;
  top: -90px;
  color: #9099b5;
  background-color: #151f3e;
  border-radius: 12px;
  width: 260px;
  padding: 10px 20px;
  /* opacity: 0; */
  display: none !important;
  text-align: center;
  font-size: 14px !important;
  z-index: 5;
}
.tooltip_up .tooltip::after {
  content: "";
  position: absolute;
  bottom: -15px;
  left: 47%;
  /* width: 30px;
  height: 30px; */
  /* z-index: 2; */
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;

  border-top: 20px solid #151f3e;
  /* transform: scaleX(0.5) translateX(-50%) rotate(45deg); */
}
.tooltip_up img {
  cursor: pointer;
}
.tooltip_up .imgHover:hover .tooltip {
  /* opacity: 1; */
  display: block !important;
}

* {
    box-sizing: border-box;
    text-decoration: none;
    font-style: normal;
    outline: none;
    box-shadow: none;
    margin: 0;
    padding: 0;
}

:before,
:after {
    box-sizing: border-box;
}

body,
html {
    font-family: 'Poppins';
    font-size: 14px;
    line-height: 1.78em;
    color: #000;
    font-weight: normal;
    -webkit-text-size-adjust: 100%;
    -webkit-tap-highlight-color: transparent;
}

/* Lists==================== */
ol,
ul {
    list-style: none;
}

/* Links, inputs and Buttons==================== */
a {
    color: inherit;
    display: inline-flex;
    align-items: center;
}

a,
.btn,
.button,
button,
input[type="button"],
input[type="button"],
input[type="submit"],
input[type="submit"],
select {
    outline: none;
    transition: all cubic-bezier(1, -0.05, 0.25, 1) 0.4s;
    cursor: pointer;
}

input,
textarea,
select {
    vertical-align: middle;
    background-color: transparent;
    border: 1px solid #ccc;
    -webkit-user-select: none;
    user-select: none;
}

select {
    -webkit-appearance: none;
}

input::-ms-clear,
input:-ms-clear {
    display: none;
}

textarea {
    resize: none;
}

:disabled,
::disabled {
    cursor: not-allowed;
}

:readonly,
::readonly {
    cursor: default;
}

::-webkit-input-placeholder {
    font-size: inherit;
    color: #bbb9d1;
}

::placeholder {
    font-size: inherit;
    color: #bbb9d1;
}

/* Tables==================== */
.table,
table {
    display: table;
    table-layout: fixed;
    width: 100%;
    border-spacing: 0;
    border-collapse: collapse;
}

td {
    vertical-align: middle;
}

/* Headings==================== */

h1,
h2,
h3,
h4,
h5,
h6 {
    margin: 0;
}

h1 {
    font-size: 4.21em;
    line-height: 75px;
}
h3 {
    font-size: 3.48em;
    line-height: 58px;
}

/* Other elements==================== */
img {
    display: inline-flex;
    max-height: 100%;
    max-width: 100%;
}

blockquote,
q {
    quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
    content: "";
    content: none;
}

br,
.clear {
    clear: both;
}

audio,
video {
    display: block;
    width: 100%;
}

figure {
    vertical-align: middle;
}

.btn {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    border: none;
}

.container {
    width: 100%;
    max-width: 106.71em;
    padding: 0 1.07em;
    margin: 0 auto;
}

.clearfix:after {
    clear: both;
}

.clearfix:after,
.clearfix:before {
    content: "";
    display: table;
}

.text-center {
    text-align: center;
}

.text-right {
    text-align: right;
}

.fz-0 {
    font-size: 0;
}

.d-flex {
    display: flex;
    align-items: center;
}

.d-inline-flex {
    display: inline-flex;
    align-items: center;
}

.f-column {
    flex-direction: column;
    align-items: stretch;
}

.m-l {
    margin-left: auto;
}

.m-b {
    margin-bottom: auto;
}

.m-t {
    margin-top: auto;
}

.m-r {
    margin-right: auto;
}

.f-1 {
    flex: 1 1 auto;
}

.f-0 {
    flex: 0 0 auto;
}

.w-100 {
    width: 100%;
}

.h-100 {
    height: 100%;
}

.f-align-center {
    align-items: center;
}

.f-justify-center {
    justify-content: center;
}

.f-justify-between {
    justify-content: space-between;
}

.main {
    height: 100vh;
    overflow: hidden;
}
span.conditioWraper {
  display: contents;
}

.approvedSuccess {
  text-align: center;
  margin-top: 10px;
  margin-bottom: -25px;
}
.approvedSuccess.otherWrapings {
  cursor: pointer;
}

.muMoneyModal.show-modal {
  z-index: 9;
}

.muMoneyModal.show-modal .modal-content {
  padding: 20px 39px 40px 39px;
  overflow: hidden;
}

/* .modalContentSecondCondition.muMoneyConvertion {
  position: relative;
} */

.whiteListWraper {
  position: absolute;
  left: 0;
  height: 100%;
  top: 0;
  width: 100%;
  /* opacity: 0.5; */
}
.whiteListInnerWraper {
  width: 100%;
  height: 100%;
  position: relative;
  margin: auto;
}
.whiteListBoxInfo {
  position: absolute;
  width: 367px;
  text-align: center;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  padding: 30px 40px;
  border-radius: 10px;
  z-index: 99;
}
.whiteLabelText p {
  letter-spacing: 1.12px;
  opacity: 1;
  font-weight: normal;
  font-size: 16px;
  margin: 20px 0 30px 0;
}

.card footer a {
  width: 100%;
}



.stableCoinRow {
  width: 100%;
  float: left;
}



.indStableCoinsWraper:nth-child(4), .indStableCoinsWraper:nth-child(8), .indStableCoinsWraper:nth-child(12), .indStableCoinsWraper:nth-child(16), .indStableCoinsWraper:nth-child(20) .indStableCoinsWraper:nth-child(24), .indStableCoinsWraper:nth-child(28), .indStableCoinsWraper:nth-child(32), .indStableCoinsWraper:nth-child(36), .indStableCoinsWraper:nth-child(40), .indStableCoinsWraper:nth-child(44), .indStableCoinsWraper:nth-child(48), .indStableCoinsWraper:nth-child(52), .indStableCoinsWraper:nth-child(56), .indStableCoinsWraper:nth-child(60), .indStableCoinsWraper:nth-child(64)  {
  margin-right: 0;
}

.stablecoinInfo {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
}

.stablecoinInfo img {
  width: 20px;
  margin-right: 5px;
}
.indStableCoinsWraper h4 {
  text-align: left;
  font: normal normal normal 16px/25px Poppins;
  letter-spacing: 0.64px;
  opacity: 1;
  font-weight: normal;
}

.stablecoinInfo p {
  text-align: left;
  font: normal normal normal 12px/18px Poppins;
  letter-spacing: 0.48px;
  opacity: 1;
}

.indStableCoinsWraper {  
  border-radius: 20px;
  opacity: 1;
  width: 123px;
  height: 63px;
  float: left;
  padding: 7px 15px 10px 15px;
  margin-right: 10px;
  margin-bottom: 20px;
  cursor: pointer;
}


.conversionRate.muMoneyModalHeading {
  text-align: left;
  font: normal normal normal 16px/25px Poppins;
  letter-spacing: 0.64px;
  opacity: 1;
  font-weight: normal;
  margin-bottom: 30px;
}

.modalSubHedading.muMoneyModal .wrapUnwrap {
  text-align: left;
  font: normal normal normal 16px/25px Poppins;
  letter-spacing: 0.64px;
  opacity: 1;
}
.modalSubHedading.muMoneyModal .balanceRate {
  text-align: left;
  font: normal normal normal 16px/25px Poppins;
  letter-spacing: 0.64px;
  opacity: 1;
}

.miningInfo p {
  text-align: center;
  font-size: 12px;
  font-weight: normal;
  letter-spacing: 0px;
  }
  
  .miningInfo p span {
    color: #0DB807;
  }

  .muMoneyRedeem {
    margin-top: 0;
  }
  .redeemWrap {
    margin-bottom: 20px;
  }
  .redeemMaxAmt {
    text-align: right;
    font-size: 14px;
    font-weight: normal;
    letter-spacing: 0px;
    opacity: 1;
    margin-left: 10px;
  }
  .redeemBtns {
    width: 118px;
    padding: 10px;
    font-size: 14px;
    display: flex;
    align-items: center;
  }

  .currencyIcon {
    padding-right: 10px;
    width: 124px;
  }
  

.main {
  background-color: #000b2c;
  background-image: url(/static/media/mu-bg.e5047b01.png), url(/static/media/bgPattern.74011827.png),
    url(/static/media/bgPattern2.a42a0419.png);
  background-repeat: no-repeat, no-repeat, no-repeat;
  background-position: left center, right 54.9375em top, right 0 top 5em;
  background-size: 19.625em, 65.0625em 34.375em, 28.25em 42.25em;
}
.footer {
  background-color: #151f3e;
}
.btn-theme-dark {
  background: #fff;
}
.btn-dark {
  background-color: #151f3e;
  color: #fff;
}
.btn-dark:hover {
  background: rgb(75 32 147 / 48%);
}
.header-links ul li a {
  color: #ffffff;
}

.copyText p {
  color: #9099b5;
}
.copyText p a {
  color: #fff;
}
.headerMain {
  color: #fff;
}
.muAmtComp h5 {
  color: #fff;
}
.muAmtComp span {
  color: #d6d6d6;
}
.footerLink li a:hover *[fill="#fff"] {
  transition: all cubic-bezier(0.165, 0.84, 0.44, 1) 0.4s;
  fill: #ff1aa1;
}
.footerLink li a:hover *[stroke="#fff"] {
  transition: all cubic-bezier(0.165, 0.84, 0.44, 1) 0.4s;
  stroke: #ff1aa1;
}
.header-options .btn-double {
  background: #000b2c;
  color: #ffffff;
  border: 1px solid #ffd700;
  padding-top: 3px;
}
.header-options .btn-double .bottomDeck {
  border-color: #ffd700;
}
.currentValueDropdown {
  width: 350px;
  background: #000b2c;
  box-shadow: 0px 12px 20px #00000061;
  border: 1px solid #ffd700;
  opacity: 1;
  border-radius: 5px;
  position: absolute;
  right: 0;
  top: 115%;
  z-index: 9;
}

.currentValueDropdown.displayDropdown {
  display: block;
}

.noBalance.currentValueDropdown p {
  color: #fff;
  font-size: 1em;
  margin-bottom: 5px;
  text-align: center;
}

.noBalance.currentValueDropdown h5 {
  font-size: 1.25em;
  color: #ffd700;
  text-align: center;
}

.balanceInfoLi h5 {
  color: #fff;
  font-size: 1em;
}

.balanceInfoLi span {
  color: #d6d6d6;
  font-size: 1em;
}

.balanceInfoLi span.valuationSpan {
  font-size: 1em;
  color: #fff;
}
.balanceInfoLi span.valueSpan {
  font-size: 0.85em;
}

.individualListingLi:hover {
  background: rgb(18 30 86 / 68%);
}

.individualListingLi {
  border-bottom: 1px solid #2f3851;
}
.totalValue {
  color: #d6d6d6;
}
.totalValuation {
  color: #fff;
}
.disconnectedBtns {
  background: #151f3e;
  color: #fff;
}

.disconnectedBtns:hover {
  background: rgb(41 60 116 / 56%);
}

.muAmtComp {
  background: transparent
    linear-gradient(270deg, #1722437a 0%, var(--unnamed-color-d2d2d2) 100%) 0%
    0% no-repeat padding-box;
  background: transparent
    linear-gradient(270deg, #282b4d 0%, rgba(83, 87, 111, 0.2) 100%) 0% 0%
    no-repeat padding-box;
}

.muGold .muAmtComp {
  background: transparent
    linear-gradient(270deg, #1722437a 0%, rgba(255, 217, 0, 0.3) 100%) 0% 0%
    no-repeat padding-box;
}
.muOre .muAmtComp {
  background: transparent
    linear-gradient(270deg, #1722437a 0%, var(--unnamed-color-ffbfa2) 100%) 0%
    0% no-repeat padding-box;
  background: transparent
    linear-gradient(270deg, #1722437a 0%, rgba(255, 191, 162, 0.3) 100%) 0% 0%
    no-repeat padding-box;
}
.muVault .muAmtComp {
  background: transparent
    linear-gradient(270deg, #1722437a 0%, var(--unnamed-color-9099b5) 100%) 0%
    0% no-repeat padding-box;
  background: transparent linear-gradient(270deg, #1722437a 0%, #9099b5 100%) 0%
    0% no-repeat padding-box;
}

.secondChild span {
  color: #d6d6d6;
}

.goDark {
  display: none;
}

/* Modal Css Start */

.modal {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgb(0 11 44 / 90%);
  opacity: 0;
  visibility: hidden;
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
  transition: visibility 0s linear 0.25s, opacity 0.25s 0s, -webkit-transform 0.25s;
  transition: visibility 0s linear 0.25s, opacity 0.25s 0s, transform 0.25s;
  transition: visibility 0s linear 0.25s, opacity 0.25s 0s, transform 0.25s, -webkit-transform 0.25s;
}

.modal-content {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  padding: 20px 39px 40px 39px;
  width: 43.17em;
  background: transparent linear-gradient(128deg, #e2e0e066 0%, #e2e0e00d 100%)
    0% 0% no-repeat padding-box;
  border: 1px solid #e2e0e099;
  border-radius: 10px;
  opacity: 1;
  backdrop-filter: blur(40px);
  -webkit-backdrop-filter: blur(40px);
}

.show-modal {
  opacity: 1;
  visibility: visible;
  -webkit-transform: scale(1);
          transform: scale(1);
  transition: visibility 0s linear 0s, opacity 0.25s 0s, -webkit-transform 0.25s;
  transition: visibility 0s linear 0s, opacity 0.25s 0s, transform 0.25s;
  transition: visibility 0s linear 0s, opacity 0.25s 0s, transform 0.25s, -webkit-transform 0.25s;
}

.conversionRate {
  color: #ffffff;
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 20px;
  cursor: pointer;
}

.wrapUnwrap {
  font-size: 20px;
  color: #9099b5;
}

.balanceRate {
  font-size: 16px;
  color: #9099b5;
  margin-left: auto;
}

.inputFieldSection input {
  color: #fff;
}

.swapBtn {
  /* background: transparent linear-gradient(90deg, #BC00FF 0%, #FF1AA1 100%) 0% 0% no-repeat padding-box;
box-shadow: 0px 10px 30px #94006733; */
  border-radius: 25px;
  opacity: 1;
  width: 100%;
  font-size: 1em;
  padding: 1.07em 0;
  border: none;
  outline: none;
}

.buttonDiv {
  width: 100%;
  display: flex;
  align-items: center;
  margin-top: 40px;
}

.maxButton.halfBtn {
  width: 49%;
  margin-right: auto;
  padding: 1.07em 0;
}

.swapBtn.btnHalf {
  width: 49%;
  margin-left: auto;
}

.disabledBtn {
  background: #151f3e;
  cursor: none;
  color: #9099b5;
}
.modal-content.succesFailResult {
  background-image: url(/static/media/success.e065ad05.png);
}

.firstConvertRow {
  display: flex;
  align-items: center;
  background: #283250 0% 0% no-repeat padding-box;
  border: 1px solid #9099b5;
  border-radius: 42px;
  opacity: 1;
  padding: 0.85em 1.01em;
  height: 54px;
}

.valueBtn {
  background: #151f3e;
}
.valueBtn:hover {
  background: rgb(18 30 86 / 68%);
}
/* Modal Css End */

.maxButton.btn-gradient.halfBtn,
.swapBtn.btn-gradient.approved.btnHalf {
  background: transparent linear-gradient(90deg, #bc00ff 0%, #ff1aa1 100%) 0% 0%
    no-repeat padding-box;
  box-shadow: 0 0.7em 2.14em #94006733;
  color: #fff;
}
.swapBtn.btn-gradient.disabled.btnHalf,
.maxButton.btn-disabled.halfBtn {
  background: #151f3e 0% 0% no-repeat padding-box;
  color: var(--unnamed-color-9099b5);
  cursor: not-allowed;
  color: #fff;
  opacity: 0.45;
  box-shadow: 0 0.7em 2.14em #02000133;
}
.approvedSuccess {
  color: #ebeaea;
}

.approvingSuccess {
  color: #fff;
}

.muGoldValues span {
  color: #fff;
}

.muGoldValues {
  background: transparent linear-gradient(269deg, #a5a399 0%, #bf99b2 100%) 0%
    0% no-repeat padding-box;
}

/* Mu Money Css */

.conversionRate.muMoneyModalHeading {
  color: #9099b5;
}

.modalSubHedading.muMoneyModal .wrapUnwrap {
  color: #ffffff;
}
.modalSubHedading.muMoneyModal .balanceRate {
  color: #ffffff;
}

.indStableCoinsWraper {
  background: #2a3148 0% 0% no-repeat padding-box;
  border: 1px solid transparent;
}
.indStableCoinsWraper:hover,
.indStableCoinsWraper.activated {
  border: 1px solid #ff1aa1;
}

.whiteLabelText p {
  color: #ffffff;
}

.stablecoinInfo p {
  color: #d6d6d6;
}

.indStableCoinsWraper h4 {
  color: #ffffff;
}

.whiteListWraper {
  background: rgb(0 11 44 / 50%);
}
.whiteListBoxInfo {
  background: #000b2c 0% 0% no-repeat padding-box;
  box-shadow: 0px 12px 20px #00000061;
  border: 1px solid #9595a4;
}

.whiteListIcon .liteAlert {
  display: none;
}

.whiteListIcon .darkAlert {
  display: block;
  margin: 0 auto;
}

.miningInfo.MuCoins {
  background: transparent linear-gradient(269deg, #252c48 0%, #d2d2d2 100%) 0%
    0% no-repeat padding-box;
}

.miningInfo.MuGold {
  background: transparent linear-gradient(269deg, #252c48 0%, #f4d974 100%) 0%
    0% no-repeat padding-box;
}

.miningInfo.MuOre {
  background: transparent linear-gradient(269deg, #252c48 0%, #ffbfa2 100%) 0%
    0% no-repeat padding-box;
}

.miningInfo.MuVault {
  background: transparent linear-gradient(269deg, #32345a 0%, #9099b5 100%) 0%
    0% no-repeat padding-box;
}

.miningInfo p {
  color: #ffffff;
}

.redeemMaxAmt {
  color: #ffffff;
}

.redeemBtns {
  color: #505a7b;
}

.currencyIcon {
  border-right: 1px solid #505a7b;
}

.redeemBtns {
  color: #fff;
}

.timerLg {
  padding: 0 50px;
  /* z-index: 4; */
  position: relative;
  justify-content: center;
  width: 93%;
  align-items: center;
  grid-gap: 15px;
  grid-gap: 15px;
  gap: 15px;
  margin: 4.6rem auto 0;
}

.timerLg .liquidity {
  background: transparent linear-gradient(89deg, #bc00ff 0%, #ff1aa1 100%) 0% 0%
    no-repeat padding-box;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  color: white;
  padding: 13px 0;
  border-radius: 120px;
  font-size: 14px;
  font-weight: 300 !important;
  letter-spacing: 1px;
  width: 12.5rem;
  height: 50px;
  text-align: center;
}
.timerLg .remainTime {
  margin-top: 5px;
}
.timerLg .remainTime > p {
  color: white;
  font-size: 17px;
  padding-bottom: 4px;
  display: flex;
  align-items: center;
  grid-gap: 5px;
  gap: 5px;
}
.timerLg .remainTime p img {
  cursor: pointer;
}
.timerLg .remainTime > div {
  grid-gap: 8px;
  gap: 8px;
  align-items: baseline;
}
.timerLg .remainTime span {
  color: white;
}
.timerLg .remainTime > div .timeSection {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  grid-gap: 4px;
  gap: 4px;
  color: white;
}
.timerLg .remainTime > div .timeSection p {
  font-size: 15px;
}
.timerLg .remainTime > div .timeSection .timeBox {
  background-color: #151f3d;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
}
.timerLg .progress {
  background-color: #151f3e;
  border-radius: 35px;
  padding: 10px 0px;
  width: 100%;
  /* height: 70px; */
  display: flex;
  align-items: center;
  justify-content: center;
}
.timerLg .main_progress_sect {
  background-color: #151f3e;
  border-radius: 20px;
  padding: 10px 20px;
  width: 65%;
  height: 86px;
  /* display: flex;
  align-items: center;
  justify-content: center; */
}
.timerLg .main_progress_sect .time_completed {
  padding-bottom: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  grid-gap: 10px;
  gap: 10px;
}
.timerLg .main_progress_sect .time_completed p > p {
  color: #9099b5;
  font-size: 16px;
  display: flex;
  align-items: center;
  grid-gap: 5px;
  gap: 5px;
}
.timerLg .main_progress_sect .time_completed div:first-of-type img {
  cursor: pointer;
  position: relative;
}

.timerLg .main_progress_sect .time_completed div:last-of-type span {
  color: #fff;
  font-size: 15px;
  font-weight: 400;
}
.timerLg .progress span {
  color: white;
}
.timerLg .progress .progress-bar__wrapper {
  position: relative;
  width: 100%;
  margin-bottom: -4px;
  margin-right: 10px;
}

.timerLg .progress progress {
  width: 100%;
  height: 20px;
  border-radius: 50px;
  /* background-color: #e2e0e066; */
  background: transparent linear-gradient(92deg, #e2e0e066 0%, #e2e0e00d 100%)
    0% 0% no-repeat padding-box;
  transition: width 300ms ease;
}
.timerLg .progress progress[value]::-webkit-progress-bar {
  width: 100%;
  height: 20px;
  border-radius: 50px;
  background: transparent linear-gradient(92deg, #e2e0e066 0%, #e2e0e00d 100%)
    0% 0% no-repeat padding-box;
  -webkit-transition: width 300ms ease;
  transition: width 300ms ease;
}
.timerLg .progress progress[value]::-webkit-progress-value {
  border-radius: 100px;
  background: transparent linear-gradient(180deg, #bc00ff 0%, #ff1aa1 100%) 0%
    0% no-repeat padding-box;
  box-shadow: 0px 0px 14px #f817ab;
  -webkit-transition: width 300ms ease;
  transition: width 300ms ease;
}

@media only screen and (max-width: 1550px) {
  .header {
    padding: 1.1em 0 0 0;
  }
  .header-links {
    margin-left: 10.85em;
  }
  .header-links ul li:not(:last-child) {
    margin-right: 3em;
  }
  .header-links ul li a {
    font-size: 1.15em;
  }
}
@media only screen and (max-width: 1450px) {
  .mucards {
    margin-top: 5.2em;
    grid-column-gap: 2.02em;
    -webkit-column-gap: 2.02em;
            column-gap: 2.02em;
  }

  .contentMain {
    padding-top: 1.55em;
    padding-bottom: 1.55em;
  }
  .header-options .btn {
    min-height: 3.57em !important;
  }
}

@media only screen and (max-width: 1350px) {
  .header-links {
    margin-left: 7.50em;
  }
  h1 {
    font-size: 3.21em;
    line-height: 55px;
  }
  h3 {
    font-size: 2.50em;
    line-height: 43px;
  }
  .card {
    padding: 1.02em 1.02em;
    height: 22.35em;
  }
  .btn-default {
    padding: 0 0.75em;
    height: 3.27em;
  }
}
.lite {
  background-color: #fff;
  background-image: url(/static/media/mu-bg.e5047b01.png),
    url(/static/media/bgPattern_lite.8d01992e.png), url(/static/media/bgPattern2.a42a0419.png);
  background-repeat: no-repeat, no-repeat, no-repeat;
  background-position: left center, right 54.9375em top, right 0 top 5em;
  background-size: 19.625em, 65.0625em 34.375em, 28.25em 42.25em;
}
.lite .footer {
  background-color: #f8f8f8;
}
.lite .btn-theme-dark {
  background: #fff;
  box-shadow: 0px 2px 15px #ff1aa133;
}
.lite .btn-dark {
  background-color: #9099b5;
  color: #fff;
}
.lite .header-links ul li a {
  color: #000b2c;
}

.lite .copyText p {
  color: #9099b5;
}
.lite .copyText p a {
  color: #151f3e;
}
.lite .headerMain {
  color: #000b2c;
}
.lite .muAmtComp h5 {
  color: #000b2c;
  font-weight: 500;
}
.lite .muAmtComp span {
  color: #000b2c;
}

lite .footerLink li a svg g {
  transition: all cubic-bezier(0.165, 0.84, 0.44, 1) 0.4s;
  fill: #f8f8f8;
}
.lite .footerLink li a svg g {
  transition: all cubic-bezier(0.165, 0.84, 0.44, 1) 0.4s;
  stroke: #737787;
  fill: #f8f8f8;
}

lite .footerLink li a svg g:hover {
  transition: all cubic-bezier(0.165, 0.84, 0.44, 1) 0.4s;
  fill: #737787;
}
.lite .footerLink li a svg g:hover {
  transition: all cubic-bezier(0.165, 0.84, 0.44, 1) 0.4s;
  stroke: #f8f8f8;
  fill: #737787;
}

.lite .footerLink li a:hover *[fill="#000b2a"] {
  transition: all cubic-bezier(0.165, 0.84, 0.44, 1) 0.4s;
  fill: #737787;
}
.lite .footerLink li a:hover *[stroke="#000b2a"] {
  transition: all cubic-bezier(0.165, 0.84, 0.44, 1) 0.4s;
  stroke: #f8f8f8;
}

.lite .footerLink li a:hover *[fill="#000b2c"] {
  transition: all cubic-bezier(0.165, 0.84, 0.44, 1) 0.4s;
  fill: #ff1aa1;
}
.lite .footerLink li a:hover *[stroke="#000b2c"] {
  transition: all cubic-bezier(0.165, 0.84, 0.44, 1) 0.4s;
  stroke: #ff1aa1;
}
.lite .footerLink li a:hover *[fill="#000b2a"] {
  transition: all cubic-bezier(0.165, 0.84, 0.44, 1) 0.4s;
  fill: #ff1aa1;
}
.lite .footerLink li a:hover *[stroke="#000b2a"] {
  transition: all cubic-bezier(0.165, 0.84, 0.44, 1) 0.4s;
  stroke: #ff1aa1;
}

.lite .header-options .btn-double {
  background: #ebf0ff;
  color: #000b2c;
  border: 1px solid #505a7b;
  padding-top: 3px;
}
.lite .header-options .btn-double .bottomDeck {
  border-color: #505a7b;
}

.bottomDeckLite {
  color: #9099b5;
}

.lite .currentValueDropdown {
  width: 350px;
  background: #ebf0ff;
  box-shadow: 0px 12px 20px #00000033;
  border: 1px solid #505a7b;
  opacity: 1;
  border-radius: 5px;
  position: absolute;
  right: 0;
  top: 115%;
  z-index: 9;
}

.lite .currentValueDropdown.displayDropdown {
  display: block;
}

.lite .noBalance.currentValueDropdown p {
  color: #505a7b;
  font-size: 1em;
  margin-bottom: 5px;
  text-align: center;
}

.lite .noBalance.currentValueDropdown h5 {
  font-size: 1.25em;
  color: #000b2c;
  text-align: center;
}

.lite .balanceInfoLi h5 {
  color: #000b2c;
  font-size: 1em;
  font-weight: 500;
}

.lite .balanceInfoLi span {
  color: #000b2c;
  font-size: 1em;
}

.lite .balanceInfoLi span.valuationSpan {
  font-size: 1em;
  color: #000b2c;
}
.lite .balanceInfoLi span.valueSpan {
  font-size: 0.85em;
}

.lite .secondChild span {
  color: #9099b5;
}

.lite .individualListingLi:hover {
  background: rgb(215 221 239 / 76%);
}

.lite .individualListingLi {
  border-bottom: 1px solid #cacfe1;
}

.lite .individualListingLi.disconBtn,
.lite .individualListingLi.totals {
  border-bottom: none;
}

.lite .totalValue {
  color: #9099b5;
}
.lite .totalValuation {
  color: #000b2c;
}

.lite .disconnectedBtns {
  background: #9099b5;
  color: #fff;
}
.lite .disconnectedBtns:hover,
.lite .btn-dark:hover {
  background: rgb(174 181 203 / 92%);
}

.lite .individualListingLi.totals:hover,
.lite .individualListingLi.disconBtn:hover {
  background: #ebf0ff;
}

.lite .disconnectedBtns:hover {
  background: #abaeb7;
  backdrop-filter: blur(40px);
  -webkit-backdrop-filter: blur(40px);
}

.lite .muAmtComp {
  background: transparent
    linear-gradient(270deg, #ffffff7a 0%, var(--unnamed-color-e3e3e3) 100%) 0%
    0% no-repeat padding-box;
  background: transparent linear-gradient(270deg, #ffffff7a 0%, #e3e3e3 100%) 0%
    0% no-repeat padding-box;
}

.lite .muGold .muAmtComp {
  background: transparent linear-gradient(270deg, #ffffff7a 0%, #fbec9b 100%) 0%
    0% no-repeat padding-box;
}
.lite .muOre .muAmtComp {
  background: transparent
    linear-gradient(270deg, #fefefe7a 0%, var(--unnamed-color-ffe1d4) 100%) 0%
    0% no-repeat padding-box;
  background: transparent linear-gradient(270deg, #fefefe7a 0%, #ffe1d4 100%) 0%
    0% no-repeat padding-box;
}
.lite .muVault .muAmtComp {
  background: transparent
    linear-gradient(270deg, #ffffff7a 0%, var(--unnamed-color-b3bad1) 100%) 0%
    0% no-repeat padding-box;
  background: transparent linear-gradient(270deg, #ffffff7a 0%, #b3bad1 100%) 0%
    0% no-repeat padding-box;
}
.lite .muAmtComp .secondInfoRow span {
  color: #7b7b7b;
}

/* Modal Css Start */

.lite .modal {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgb(80 90 123 / 95%);
  opacity: 0;
  visibility: hidden;
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
  transition: visibility 0s linear 0.25s, opacity 0.25s 0s, -webkit-transform 0.25s;
  transition: visibility 0s linear 0.25s, opacity 0.25s 0s, transform 0.25s;
  transition: visibility 0s linear 0.25s, opacity 0.25s 0s, transform 0.25s, -webkit-transform 0.25s;
}

.lite .show-modal {
  opacity: 1;
  visibility: visible;
  -webkit-transform: scale(1);
          transform: scale(1);
  transition: visibility 0s linear 0s, opacity 0.25s 0s, -webkit-transform 0.25s;
  transition: visibility 0s linear 0s, opacity 0.25s 0s, transform 0.25s;
  transition: visibility 0s linear 0s, opacity 0.25s 0s, transform 0.25s, -webkit-transform 0.25s;
}

.lite .conversionRate {
  color: #ffffff;
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 20px;
  cursor: pointer;
}

.lite .wrapUnwrap {
  font-size: 20px;
  color: #000b2c;
}

.lite .balanceRate {
  font-size: 16px;
  color: #000b2c;
  margin-left: auto;
}

.lite .inputFieldSection input {
  color: #000b2c;
}

.lite .swapBtn {
  border-radius: 25px;
  opacity: 1;
  width: 100%;
  font-size: 1em;
  color: #fff;
  padding: 1.07em 0;
  border: none;
  outline: none;
}

.lite .modalSuccess .swapBtn {
  width: 320px;
}

.buttonDiv {
  width: 100%;
  display: flex;
  align-items: center;
  margin-top: 40px;
}

.lite .maxButton.halfBtn {
  width: 49%;
  margin-right: auto;
  padding: 1.07em 0;
  color: #ffffff;
  background: #9099b5;
}
.lite .swapBtn.btnHalf {
  width: 49%;
  margin-left: auto;
}

.lite .firstConvertRow .btnMax .maxButton {
  background: #9099b5;
}

.lite .firstConvertRow .btnMax .maxButton:hover {
  background: rgb(108 117 145 / 81%);
}

.lite .firstConvertRow {
  display: flex;
  align-items: center;
  background: #ebf0ff 0% 0% no-repeat padding-box;
  border: 1px solid #9099b5;
  border-radius: 42px;
  opacity: 1;
  padding: 0.85em 1.01em;
  height: 54px;
}

.lite .modal-content.succesFailResult {
  background-image: url(/static/media/success.e065ad05.png);
}
/* .valueBtn {
  background: #151F3E;
}
.valueBtn:hover {
  background: 
} */
/* Modal Css End */

.lite .goLite.goDark {
  display: block !important;
}

.lite .goLite {
  display: none;
}

.lite .maxButton.btn-gradient.halfBtn,
.lite .swapBtn.btn-gradient.approved.btnHalf {
  background: transparent linear-gradient(90deg, #bc00ff 0%, #ff1aa1 100%) 0% 0%
    no-repeat padding-box;
  box-shadow: 0 0.7em 2.14em #94006733;
  color: #fff;
}

.lite .swapBtn.btn-gradient.disabled.btnHalf,
.lite .maxButton.btn-disabled.halfBtn {
  background: var(--unnamed-color-9099b5) 0% 0% no-repeat padding-box;
  color: var(--unnamed-color-9099b5);
  cursor: not-allowed;
  color: #fff;
  opacity: 0.45;
  box-shadow: 0 0.7em 2.14em #02000133;
}

.lite .approvedSuccess {
  color: #000b2c;
}

.lite .approvingSuccess {
  color: #000b2c;
}

.lite .muGoldValues {
  background: transparent linear-gradient(269deg, #ffedab 0%, #ffd2ef 100%) 0%
    0% no-repeat padding-box;
}

.lite .muGoldValues span {
  color: #7b7b7b;
}

/* Mu Money New design styling starts */

.lite .whiteListBoxInfo {
  background: #ebf0ff 0% 0% no-repeat padding-box;
  box-shadow: 0px 12px 20px #00000033;
  border: 1px solid #505a7b;
}

.lite .whiteLabelText p {
  color: #505a7b;
}

.lite .whiteListIcon .liteAlert {
  display: block;
  margin: 0 auto;
}

.lite .whiteListIcon .darkAlert {
  display: none;
}

.lite .modalSubHedading.muMoneyModal .wrapUnwrap,
.lite .modalSubHedading.muMoneyModal .balanceRate {
  color: #000b2c;
}

.lite .indStableCoinsWraper {
  background: #e4e7f1 0% 0% no-repeat padding-box;
  border: 1px solid transparent;
}
.lite .indStableCoinsWraper:hover,
.lite .indStableCoinsWraper.activated {
  border: 1px solid #ff1aa1;
}
.lite .stablecoinInfo p {
  color: #9099b5;
}

.lite .indStableCoinsWraper h4 {
  color: #000b2c;
}
.lite .miningInfo.MuCoins {
  background: transparent linear-gradient(269deg, #fcfaf8 0%, #d2d2d2 100%) 0%
    0% no-repeat padding-box;
}
.lite .miningInfo.MuGold {
  background: transparent linear-gradient(269deg, #fcfaf8 0%, #f4d974 100%) 0%
    0% no-repeat padding-box;
}
.lite .miningInfo.MuOre {
  background: transparent linear-gradient(269deg, #fcfaf8 0%, #ffbfa2 100%) 0%
    0% no-repeat padding-box;
}
.lite .miningInfo.MuVault {
  background: transparent linear-gradient(266deg, #fefefe 0%, #afb6ca 100%) 0%
    0% no-repeat padding-box;
}
.lite .miningInfo p {
  color: #5d5b5b;
}
.lite .redeemMaxAmt {
  color: #ffffff;
}
.lite .redeemBtns {
  color: #151f3e;
}
.lite .currencyIcon {
  border-right: 1px solid #9099b5;
}

.lite .timerLg {
  padding: 0 50px;
  /* z-index: 4; */
  position: relative;
  justify-content: center;
  width: 93%;
  align-items: center;
  grid-gap: 15px;
  grid-gap: 15px;
  gap: 15px;
  margin: 4.6rem auto 0;
}
.lite .timerLg .liquidity {
  background: transparent linear-gradient(89deg, #bc00ff 0%, #ff1aa1 100%) 0% 0%
    no-repeat padding-box;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  color: white;
  padding: 13px 0;
  border-radius: 120px;
  font-size: 14px;
  font-weight: 300 !important;
  letter-spacing: 1px;
  width: 12.5rem;
  height: 50px;
  text-align: center;
}
.lite .timerLg .remainTime > p {
  color: #000;
  font-size: 16px;
  padding-bottom: 4px;
  display: flex;
  align-items: center;
  grid-gap: 5px;
  gap: 5px;
}
.lite .timerLg .remainTime p img {
  cursor: pointer;
}
.lite .timerLg .remainTime > div {
  grid-gap: 8px;
  gap: 8px;
  align-items: baseline;
}
.lite .timerLg .remainTime span {
  color: #000b2c;
}
.lite .timerLg .remainTime > div .timeSection {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  grid-gap: 4px;
  gap: 4px;
  color: #000b2c;
}
.lite .timerLg .remainTime > div .timeSection .timeBox {
  background-color: #f8f8f8;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
}
.lite .timerLg .main_progress_sect {
  background-color: #f8f8f8;
  border-radius: 20px;
  padding: 10px 20px;
  width: 65%;
  height: 86px;
  /* display: flex;
  align-items: center;
  justify-content: center; */
}
.lite .timerLg .main_progress_sect .time_completed {
  padding-bottom: 20px;
}
.lite .timerLg .main_progress_sect .time_completed div:first-of-type {
  color: #9099b5;
  font-size: 16px;
  display: flex;
  align-items: center;
  grid-gap: 5px;
  gap: 5px;
}

.lite .timerLg .main_progress_sect .time_completed div:last-of-type span {
  color: #000;
  font-size: 15px;
  font-weight: 400;
}
.lite .timerLg .main_progress_sect .time_completed {
  padding-bottom: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  grid-gap: 10px;
  gap: 10px;
}
.lite .timerLg .progress {
  background-color: #f8f8f8;
  border-radius: 35px;
  padding: 10px 0px;
  width: 100%;
  /* height: 70px; */
  display: flex;
  align-items: center;
  justify-content: center;
}
.lite .timerLg .progress span {
  color: #000b2c;
}
.lite .timerLg .progress .progress-bar__wrapper {
  position: relative;
  width: 100%;
  margin-bottom: -4px;
  margin-right: 10px;
}

.lite .timerLg .progress progress {
  width: 100%;
  height: 20px;
  border-radius: 50px;
  background-color: #e2e0e066;
  transition: width 300ms ease;
}
.lite .timerLg .progress progress[value]::-webkit-progress-bar {
  width: 100%;
  height: 20px;
  border-radius: 50px;
  background-color: #e2e0e066;
  -webkit-transition: width 300ms ease;
  transition: width 300ms ease;
}
.lite .timerLg .progress progress[value]::-webkit-progress-value {
  /* width: 0; */
  border-radius: 100px;
  background: transparent linear-gradient(180deg, #bc00ff 0%, #ff1aa1 100%) 0%
    0% no-repeat padding-box;
  box-shadow: 0px 0px 14px #f817ab;
  -webkit-transition: width 300ms ease;
  transition: width 300ms ease;
}
.lite .tooltip_up .tooltip {
  background-color: #f8f8f8;
}

.lite .tooltip_up .tooltip::after {
  border-top-color: #f8f8f8;
}
@media only screen and (max-width: 1550px) {
  .lite .header {
    padding: 1.1em 0 0 0;
  }
  .lite .header-links {
    margin-left: 10.85em;
  }
  .lite .header-links ul li:not(:last-child) {
    margin-right: 3em;
  }
  .lite .header-links ul li a {
    font-size: 1.15em;
  }
}
@media only screen and (max-width: 1450px) {
  .lite .mucards {
    margin-top: 5.2em;
    grid-column-gap: 2.02em;
    -webkit-column-gap: 2.02em;
            column-gap: 2.02em;
  }

  .lite .contentMain {
    padding-top: 1.55em;
    padding-bottom: 1.55em;
  }
}

@media only screen and (max-width: 1350px) {
  .lite .header-links {
    margin-left: 7.50em;
  }
  .lite h1 {
    font-size: 3.21em;
    line-height: 55px;
  }
  .lite h3 {
    font-size: 2.50em;
    line-height: 43px;
  }
  .lite .card {
    padding: 1.02em 1.02em;
    height: 22.35em;
  }
  .lite .btn-default {
    padding: 0 0.75em;
    height: 3.99 em;
  }
}
