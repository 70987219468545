.lite {
  background-color: #fff;
  background-image: url("../images/mu-bg.png"),
    url("../images/bgPattern_lite.png"), url("../images/bgPattern2.png");
  background-repeat: no-repeat, no-repeat, no-repeat;
  background-position: left center, right 54.9375em top, right 0 top 5em;
  background-size: 19.625em, 65.0625em 34.375em, 28.25em 42.25em;
}
.lite .footer {
  background-color: #f8f8f8;
}
.lite .btn-theme-dark {
  background: #fff;
  box-shadow: 0px 2px 15px #ff1aa133;
}
.lite .btn-dark {
  background-color: #9099b5;
  color: #fff;
}
.lite .header-links ul li a {
  color: #000b2c;
}

.lite .copyText p {
  color: #9099b5;
}
.lite .copyText p a {
  color: #151f3e;
}
.lite .headerMain {
  color: #000b2c;
}
.lite .muAmtComp h5 {
  color: #000b2c;
  font-weight: 500;
}
.lite .muAmtComp span {
  color: #000b2c;
}

lite .footerLink li a svg g {
  transition: all cubic-bezier(0.165, 0.84, 0.44, 1) 0.4s;
  fill: #f8f8f8;
}
.lite .footerLink li a svg g {
  transition: all cubic-bezier(0.165, 0.84, 0.44, 1) 0.4s;
  stroke: #737787;
  fill: #f8f8f8;
}

lite .footerLink li a svg g:hover {
  transition: all cubic-bezier(0.165, 0.84, 0.44, 1) 0.4s;
  fill: #737787;
}
.lite .footerLink li a svg g:hover {
  transition: all cubic-bezier(0.165, 0.84, 0.44, 1) 0.4s;
  stroke: #f8f8f8;
  fill: #737787;
}

.lite .footerLink li a:hover *[fill="#000b2a"] {
  transition: all cubic-bezier(0.165, 0.84, 0.44, 1) 0.4s;
  fill: #737787;
}
.lite .footerLink li a:hover *[stroke="#000b2a"] {
  transition: all cubic-bezier(0.165, 0.84, 0.44, 1) 0.4s;
  stroke: #f8f8f8;
}

.lite .footerLink li a:hover *[fill="#000b2c"] {
  transition: all cubic-bezier(0.165, 0.84, 0.44, 1) 0.4s;
  fill: #ff1aa1;
}
.lite .footerLink li a:hover *[stroke="#000b2c"] {
  transition: all cubic-bezier(0.165, 0.84, 0.44, 1) 0.4s;
  stroke: #ff1aa1;
}
.lite .footerLink li a:hover *[fill="#000b2a"] {
  transition: all cubic-bezier(0.165, 0.84, 0.44, 1) 0.4s;
  fill: #ff1aa1;
}
.lite .footerLink li a:hover *[stroke="#000b2a"] {
  transition: all cubic-bezier(0.165, 0.84, 0.44, 1) 0.4s;
  stroke: #ff1aa1;
}

.lite .header-options .btn-double {
  background: #ebf0ff;
  color: #000b2c;
  border: 1px solid #505a7b;
  padding-top: 3px;
}
.lite .header-options .btn-double .bottomDeck {
  border-color: #505a7b;
}

.bottomDeckLite {
  color: #9099b5;
}

.lite .currentValueDropdown {
  width: 350px;
  background: #ebf0ff;
  box-shadow: 0px 12px 20px #00000033;
  border: 1px solid #505a7b;
  opacity: 1;
  border-radius: 5px;
  position: absolute;
  right: 0;
  top: 115%;
  z-index: 9;
}

.lite .currentValueDropdown.displayDropdown {
  display: block;
}

.lite .noBalance.currentValueDropdown p {
  color: #505a7b;
  font-size: 1em;
  margin-bottom: 5px;
  text-align: center;
}

.lite .noBalance.currentValueDropdown h5 {
  font-size: 1.25em;
  color: #000b2c;
  text-align: center;
}

.lite .balanceInfoLi h5 {
  color: #000b2c;
  font-size: 1em;
  font-weight: 500;
}

.lite .balanceInfoLi span {
  color: #000b2c;
  font-size: 1em;
}

.lite .balanceInfoLi span.valuationSpan {
  font-size: 1em;
  color: #000b2c;
}
.lite .balanceInfoLi span.valueSpan {
  font-size: 0.85em;
}

.lite .secondChild span {
  color: #9099b5;
}

.lite .individualListingLi:hover {
  background: rgb(215 221 239 / 76%);
}

.lite .individualListingLi {
  border-bottom: 1px solid #cacfe1;
}

.lite .individualListingLi.disconBtn,
.lite .individualListingLi.totals {
  border-bottom: none;
}

.lite .totalValue {
  color: #9099b5;
}
.lite .totalValuation {
  color: #000b2c;
}

.lite .disconnectedBtns {
  background: #9099b5;
  color: #fff;
}
.lite .disconnectedBtns:hover,
.lite .btn-dark:hover {
  background: rgb(174 181 203 / 92%);
}

.lite .individualListingLi.totals:hover,
.lite .individualListingLi.disconBtn:hover {
  background: #ebf0ff;
}

.lite .disconnectedBtns:hover {
  background: #abaeb7;
  backdrop-filter: blur(40px);
  -webkit-backdrop-filter: blur(40px);
}

.lite .muAmtComp {
  background: transparent
    linear-gradient(270deg, #ffffff7a 0%, var(--unnamed-color-e3e3e3) 100%) 0%
    0% no-repeat padding-box;
  background: transparent linear-gradient(270deg, #ffffff7a 0%, #e3e3e3 100%) 0%
    0% no-repeat padding-box;
}

.lite .muGold .muAmtComp {
  background: transparent linear-gradient(270deg, #ffffff7a 0%, #fbec9b 100%) 0%
    0% no-repeat padding-box;
}
.lite .muOre .muAmtComp {
  background: transparent
    linear-gradient(270deg, #fefefe7a 0%, var(--unnamed-color-ffe1d4) 100%) 0%
    0% no-repeat padding-box;
  background: transparent linear-gradient(270deg, #fefefe7a 0%, #ffe1d4 100%) 0%
    0% no-repeat padding-box;
}
.lite .muVault .muAmtComp {
  background: transparent
    linear-gradient(270deg, #ffffff7a 0%, var(--unnamed-color-b3bad1) 100%) 0%
    0% no-repeat padding-box;
  background: transparent linear-gradient(270deg, #ffffff7a 0%, #b3bad1 100%) 0%
    0% no-repeat padding-box;
}
.lite .muAmtComp .secondInfoRow span {
  color: #7b7b7b;
}

/* Modal Css Start */

.lite .modal {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgb(80 90 123 / 95%);
  opacity: 0;
  visibility: hidden;
  transform: scale(1.1);
  transition: visibility 0s linear 0.25s, opacity 0.25s 0s, transform 0.25s;
}

.lite .show-modal {
  opacity: 1;
  visibility: visible;
  transform: scale(1);
  transition: visibility 0s linear 0s, opacity 0.25s 0s, transform 0.25s;
}

.lite .conversionRate {
  color: #ffffff;
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 20px;
  cursor: pointer;
}

.lite .wrapUnwrap {
  font-size: 20px;
  color: #000b2c;
}

.lite .balanceRate {
  font-size: 16px;
  color: #000b2c;
  margin-left: auto;
}

.lite .inputFieldSection input {
  color: #000b2c;
}

.lite .swapBtn {
  border-radius: 25px;
  opacity: 1;
  width: 100%;
  font-size: 1em;
  color: #fff;
  padding: 1.07em 0;
  border: none;
  outline: none;
}

.lite .modalSuccess .swapBtn {
  width: 320px;
}

.buttonDiv {
  width: 100%;
  display: flex;
  align-items: center;
  margin-top: 40px;
}

.lite .maxButton.halfBtn {
  width: 49%;
  margin-right: auto;
  padding: 1.07em 0;
  color: #ffffff;
  background: #9099b5;
}
.lite .swapBtn.btnHalf {
  width: 49%;
  margin-left: auto;
}

.lite .firstConvertRow .btnMax .maxButton {
  background: #9099b5;
}

.lite .firstConvertRow .btnMax .maxButton:hover {
  background: rgb(108 117 145 / 81%);
}

.lite .firstConvertRow {
  display: flex;
  align-items: center;
  background: #ebf0ff 0% 0% no-repeat padding-box;
  border: 1px solid #9099b5;
  border-radius: 42px;
  opacity: 1;
  padding: 0.85em 1.01em;
  height: 54px;
}

.lite .modal-content.succesFailResult {
  background-image: url("../images/success.png");
}
/* .valueBtn {
  background: #151F3E;
}
.valueBtn:hover {
  background: 
} */
/* Modal Css End */

.lite .goLite.goDark {
  display: block !important;
}

.lite .goLite {
  display: none;
}

.lite .maxButton.btn-gradient.halfBtn,
.lite .swapBtn.btn-gradient.approved.btnHalf {
  background: transparent linear-gradient(90deg, #bc00ff 0%, #ff1aa1 100%) 0% 0%
    no-repeat padding-box;
  box-shadow: 0 0.7em 2.14em #94006733;
  color: #fff;
}

.lite .swapBtn.btn-gradient.disabled.btnHalf,
.lite .maxButton.btn-disabled.halfBtn {
  background: var(--unnamed-color-9099b5) 0% 0% no-repeat padding-box;
  color: var(--unnamed-color-9099b5);
  cursor: not-allowed;
  color: #fff;
  opacity: 0.45;
  box-shadow: 0 0.7em 2.14em #02000133;
}

.lite .approvedSuccess {
  color: #000b2c;
}

.lite .approvingSuccess {
  color: #000b2c;
}

.lite .muGoldValues {
  background: transparent linear-gradient(269deg, #ffedab 0%, #ffd2ef 100%) 0%
    0% no-repeat padding-box;
}

.lite .muGoldValues span {
  color: #7b7b7b;
}

/* Mu Money New design styling starts */

.lite .whiteListBoxInfo {
  background: #ebf0ff 0% 0% no-repeat padding-box;
  box-shadow: 0px 12px 20px #00000033;
  border: 1px solid #505a7b;
}

.lite .whiteLabelText p {
  color: #505a7b;
}

.lite .whiteListIcon .liteAlert {
  display: block;
  margin: 0 auto;
}

.lite .whiteListIcon .darkAlert {
  display: none;
}

.lite .modalSubHedading.muMoneyModal .wrapUnwrap,
.lite .modalSubHedading.muMoneyModal .balanceRate {
  color: #000b2c;
}

.lite .indStableCoinsWraper {
  background: #e4e7f1 0% 0% no-repeat padding-box;
  border: 1px solid transparent;
}
.lite .indStableCoinsWraper:hover,
.lite .indStableCoinsWraper.activated {
  border: 1px solid #ff1aa1;
}
.lite .stablecoinInfo p {
  color: #9099b5;
}

.lite .indStableCoinsWraper h4 {
  color: #000b2c;
}
.lite .miningInfo.MuCoins {
  background: transparent linear-gradient(269deg, #fcfaf8 0%, #d2d2d2 100%) 0%
    0% no-repeat padding-box;
}
.lite .miningInfo.MuGold {
  background: transparent linear-gradient(269deg, #fcfaf8 0%, #f4d974 100%) 0%
    0% no-repeat padding-box;
}
.lite .miningInfo.MuOre {
  background: transparent linear-gradient(269deg, #fcfaf8 0%, #ffbfa2 100%) 0%
    0% no-repeat padding-box;
}
.lite .miningInfo.MuVault {
  background: transparent linear-gradient(266deg, #fefefe 0%, #afb6ca 100%) 0%
    0% no-repeat padding-box;
}
.lite .miningInfo p {
  color: #5d5b5b;
}
.lite .redeemMaxAmt {
  color: #ffffff;
}
.lite .redeemBtns {
  color: #151f3e;
}
.lite .currencyIcon {
  border-right: 1px solid #9099b5;
}

.lite .timerLg {
  padding: 0 50px;
  /* z-index: 4; */
  position: relative;
  justify-content: center;
  width: 93%;
  align-items: center;
  grid-gap: 15px;
  gap: 15px;
  margin: 4.6rem auto 0;
}
.lite .timerLg .liquidity {
  background: transparent linear-gradient(89deg, #bc00ff 0%, #ff1aa1 100%) 0% 0%
    no-repeat padding-box;
  width: fit-content;
  color: white;
  padding: 13px 0;
  border-radius: 120px;
  font-size: 14px;
  font-weight: 300 !important;
  letter-spacing: 1px;
  width: 12.5rem;
  height: 50px;
  text-align: center;
}
.lite .timerLg .remainTime > p {
  color: #000;
  font-size: 16px;
  padding-bottom: 4px;
  display: flex;
  align-items: center;
  gap: 5px;
}
.lite .timerLg .remainTime p img {
  cursor: pointer;
}
.lite .timerLg .remainTime > div {
  gap: 8px;
  align-items: baseline;
}
.lite .timerLg .remainTime span {
  color: #000b2c;
}
.lite .timerLg .remainTime > div .timeSection {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 4px;
  color: #000b2c;
}
.lite .timerLg .remainTime > div .timeSection .timeBox {
  background-color: #f8f8f8;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
}
.lite .timerLg .main_progress_sect {
  background-color: #f8f8f8;
  border-radius: 20px;
  padding: 10px 20px;
  width: 65%;
  height: 86px;
  /* display: flex;
  align-items: center;
  justify-content: center; */
}
.lite .timerLg .main_progress_sect .time_completed {
  padding-bottom: 20px;
}
.lite .timerLg .main_progress_sect .time_completed div:first-of-type {
  color: #9099b5;
  font-size: 16px;
  display: flex;
  align-items: center;
  gap: 5px;
}

.lite .timerLg .main_progress_sect .time_completed div:last-of-type span {
  color: #000;
  font-size: 15px;
  font-weight: 400;
}
.lite .timerLg .main_progress_sect .time_completed {
  padding-bottom: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}
.lite .timerLg .progress {
  background-color: #f8f8f8;
  border-radius: 35px;
  padding: 10px 0px;
  width: 100%;
  /* height: 70px; */
  display: flex;
  align-items: center;
  justify-content: center;
}
.lite .timerLg .progress span {
  color: #000b2c;
}
.lite .timerLg .progress .progress-bar__wrapper {
  position: relative;
  width: 100%;
  margin-bottom: -4px;
  margin-right: 10px;
}

.lite .timerLg .progress progress {
  width: 100%;
  height: 20px;
  border-radius: 50px;
  background-color: #e2e0e066;
  transition: width 300ms ease;
}
.lite .timerLg .progress progress[value]::-webkit-progress-bar {
  width: 100%;
  height: 20px;
  border-radius: 50px;
  background-color: #e2e0e066;
  transition: width 300ms ease;
}
.lite .timerLg .progress progress[value]::-webkit-progress-value {
  /* width: 0; */
  border-radius: 100px;
  background: transparent linear-gradient(180deg, #bc00ff 0%, #ff1aa1 100%) 0%
    0% no-repeat padding-box;
  box-shadow: 0px 0px 14px #f817ab;
  transition: width 300ms ease;
}
.lite .tooltip_up .tooltip {
  background-color: #f8f8f8;
}

.lite .tooltip_up .tooltip::after {
  border-top-color: #f8f8f8;
}